<template>
  <div class="main_login">
    <div class="login_box">
      <div class="back_image">
        <div class="hide_logo"
          style="
            position: absolute;
            top: 30px;
            left: 30px;
            display: flex;
            flex-direction: row;
          "
        >
          <img class="hide_logo"
            style="height: 80px; z-index: 999"
            src="@/assets/blueJ_logo.svg"
          />
          <h2 class="hide_logo"
            style="
              margin-top: 20px;
              color: white;
              font-size: 2rem;
              z-index: 999;
            "
          >
            Blu J
          </h2>
        </div>
        <div style="background-color: blue">
          <img style="height: 100%; opacity: 0.5" src="@/assets/loginimg.png" />
        </div>
      </div>
    </div>
    <div class="login_box">
      <div class="centeredSpinnerWrapper" v-if="showSpinner">
        <SpinnerComponent class="centeredSpinner"></SpinnerComponent>
      </div>
      <div v-else>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
          "
        >
          <img class="logo" src="@/assets/blueJ_logo _blue.svg" />
          <img class="logo_small" src="@/assets/blueJ_logo _blue.svg" />
          <h1 class="welcome_text">Welcome !</h1>
        </div>

        <br />
        <br />

        <div
          v-if="showUnauthorizedDialog"
          style="width: 100%; display: flex; justify-content: center"
        >
          <loginErroralert></loginErroralert>
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
          "
        >
          <v-btn
            class="login_btn"
            v-on:click="getAuthCode"
            depressed
            outlined 
            :disabled="!isGauthInit"
            style="height:60px;
              border: 1px solid black;
              width: 100%;
              font-size: 1rem;
              font-weight: 600;
              margin: 10px; 
              color: black;
              box-shadow: 0px 0px 2px 1px;
              padding:20px; "
          >
            <img :style="!isGauthInit ? 'opacity:50%;' : ''"  style="height: 30px; width: 30px" src="@/assets/Group.svg" />
            &nbsp; &nbsp; <p class="btn_name">Login with Google</p>
          </v-btn>

          <v-btn
            class="login_btn"
            :disabled="!hasOneLoginUrl"
            :href="oneLoginAuthURL"
            depressed
            outlined 
            style="height:60px;
              border: 1px solid black;
              width: 100%;
              font-size: 1rem;
              font-weight: 600;
              margin: 10px; 
              color: black;
              box-shadow: 0px 0px 2px 1px;
              padding:20px; "
          >
            <img :style="!hasOneLoginUrl ? 'opacity:50%;' : ''" style="height: 30px; width: 30px" src="@/assets/Vector.svg" />
            &nbsp; &nbsp; <p class="btn_name">Login with OneLogin</p>
          </v-btn>
          <v-btn
            class="login_btn"
            depressed
            outlined
            @click="showLoginDialog" 
            style="height:60px;
              border: 1px solid black;
              width: 100%;
              font-size: 1rem;
              font-weight: 600;
              margin: 10px; 
              color: black;
              box-shadow: 0px 0px 2px 1px;
              padding:20px; "
          >
            &nbsp; &nbsp; <p class="btn_name">Login with Email</p>
          </v-btn>
        </div>
        <div v-if="showMessageDialog">
          <MessageDialog
            :displayDialog="showMessageDialog"
            :messageObject="
              errorMessageShow ? errorMessageObject : okMessageObject
            "
            @closeMessageDialog="closeMessageDialog"
          ></MessageDialog>
        </div>
        <div v-if="setloginDialog">
          <EmailLoginComponent
            @loginWithEmail="loginWithEmail"
            @clickCloseLoginDialog="clickCloseLoginDialog"
          ></EmailLoginComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageDialog from "../components/MessageDialog.vue";
import SpinnerComponent from "../components/SpinnerComponent.vue";
import Client from "@onelogin/sdk";
import loginErroralert from "../components/loginErroralert.vue";
import EmailLoginComponent from "../components/EmailLoginComponent.vue";
import {getFromLocalStorage, setToLocalStorage, removeFromLocalStorage} from "../store/localStorageInterface";
const axios = require("axios");

export default {
  components: {
    MessageDialog,
    SpinnerComponent,
    loginErroralert,
    EmailLoginComponent
  },
  data() {
    return {
      sessionValue: false,
      showUnauthorizedDialog: false,
      dialogText: "You are not authorized",
      oneLoginAuthURL: "",
      showSpinner: false,
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      gIdToken: "",
      gTdExpiry: "",
      emailIdToken:"",
      emailIdExpiry:"",
      setloginDialog:false,
      client:"",
      isGauthInit:false,
      reload: true
    }
  },
  computed: {
    hasOneLoginUrl() {
      return this.oneLoginAuthURL !="" ? true : false
    },
  },
  async mounted() {
    this.initClient()
    const onelogin = new Client({
      baseURL: process.env.VUE_APP_ONELOGIN_BASEURL,
    });

    let pkceClient = onelogin.pkce.Configure({
      redirectURL: process.env.VUE_APP_ONELOGIN_CALLBACK_URL,
      clientID: process.env.VUE_APP_ONELOGIN_CLIENT_ID,
      baseURL: process.env.VUE_APP_ONELOGIN_BASEURL,
    });

    let urlParams = new URLSearchParams(location.search);
    let code = urlParams.get("code");
    if (code) {
      this.showSpinner = true;
      let accessToken = "";
      let idToken = "";
      try {
        let token = await pkceClient.GetAccessTokenAsync(code);
        accessToken = token.access_token;
        idToken = token.id_token;
        let userInfo = await pkceClient.GetUserInfoAsync(accessToken);
        if (userInfo.email && userInfo.email != '') {
          setToLocalStorage("userId", userInfo.email);
        } else {
          setToLocalStorage("userId", userInfo.preferred_username);
        }
        setToLocalStorage("userName", userInfo.name);
        if (accessToken) {
          setToLocalStorage("accessToken", accessToken);
          if (idToken) {
            setToLocalStorage("idToken", idToken);
          }
          var data = {
            token: accessToken,
            authType: "onelogin",
          };
          axios({
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL + "/verifyUser",
            data: data,
            headers: {
              "content-type": "application/json",
            },
          })
            .then((response) => {
              if (response.data.message == "Success") {
                setToLocalStorage("authType", "onelogin");
                setToLocalStorage("token", accessToken);
                if (localStorage.getItem("currentPath")) {
                  var path = localStorage.getItem("currentPath").split('#');
                  var routePath = path[0];
                  if(path.length>1)
                  {
                    var pathUserId = path[1];
                    if(pathUserId == getFromLocalStorage('userId')){
                      this.$router.push({
                        path: routePath,
                      });
                    }
                    else{
                      localStorage.removeItem("currentPath");
                      this.$router.push({
                        name: "Home",
                      });
                    }
                  }
                } else {
                  this.$router.push({
                    name: "Home",
                  });
                }
              } else {
                this.clearAuthTokenAndCodeVerifier();
                pkceClient.CreateAuthURL().then((url) => {
                  this.showSpinner = false;
                  this.showUnauthorizedDialog = true;
                  this.oneLoginAuthURL = url;
                });
              }
            })
            .catch((error) => {
              if (error.response && error.response.status && error.response.status == 401) {
                this.clearAuthTokenAndCodeVerifier();
                pkceClient.CreateAuthURL().then((url) => {
                  this.showSpinner = false;
                  this.showUnauthorizedDialog = true;
                  this.oneLoginAuthURL = url;
                });
              }
                if(error.response && error.response.data && error.response.data.errorObject) {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: error.response.data.errorObject.error_code,
                    error_uuid: error.response.data.errorObject.error_uuid,
                  };
                } else {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: "",
                    error_uuid: "",
                  };
                }
                this.showMessageDialog = true;
                this.errorMessageShow = true;
            });
        } else {
          this.clearAuthTokenAndCodeVerifier();
          pkceClient.CreateAuthURL().then((url) => {
            this.oneLoginAuthURL = url;
            this.showSpinner = false;
            this.showUnauthorizedDialog = true;
          });
        }
      } catch (error) {
        this.clearAuthTokenAndCodeVerifier();
        pkceClient.CreateAuthURL().then((url) => {
          this.oneLoginAuthURL = url;
          this.showSpinner = false;
          this.showUnauthorizedDialog = true;
        });
      }
    } else {
      pkceClient.CreateAuthURL().then((url) => {
        this.oneLoginAuthURL = url;
        let mode = urlParams.get("mode");
        if (mode == "onelogin") {
          window.location.href = url;
        }
      });
      let token = getFromLocalStorage("token");
      let authType = getFromLocalStorage("authType");
      if (token && authType) {
        this.showSpinner = true;
        var data = {
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
        };
        axios({
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL + "/verifyUser",
          data: data,
          headers: {
            "content-type": "application/json",
          },
        })
          .then((response) => {
            if (response.data.message == "Success") {
              if (response.data.userNotificationsData) {
                this.$store.dispatch("replaceNotifications", response.data.userNotificationsData);
              }
              if (localStorage.getItem("currentPath")) {
                var path = localStorage.getItem("currentPath").split('#');
                var routePath = path[0];
                if(path.length>1)
                {
                  var pathUserId = path[1];
                  if(pathUserId == getFromLocalStorage('userId')){
                    this.$router.push({
                      path: routePath,
                    });
                  }
                  else{
                    localStorage.removeItem("currentPath");
                    this.$router.push({
                      name: "Home",
                    });
                  }
                }
                else{
                  localStorage.removeItem("currentPath");
                  this.$router.push({
                    name: "Home",
                  });
                }
              } else {
                this.$router.push({
                  name: "Home",
                });
              }
            } else {
              this.clearAuthTokenAndCodeVerifier();
              this.showSpinner = false;
              this.showUnauthorizedDialog = true;
            }
          })
          .catch((error) => {
            if (error.response && error.response.status && error.response.status == 401) {
              this.clearAuthTokenAndCodeVerifier();
              const onelogin = new Client({
                baseURL: process.env.VUE_APP_ONELOGIN_BASEURL,
              });
              let pkceClient = onelogin.pkce.Configure({
                redirectURL: process.env.VUE_APP_ONELOGIN_CALLBACK_URL,
                clientID: process.env.VUE_APP_ONELOGIN_CLIENT_ID,
                baseURL: process.env.VUE_APP_ONELOGIN_BASEURL,
              });
              pkceClient.CreateAuthURL().then((url) => {
                this.showSpinner = false;
                this.showUnauthorizedDialog = true;
                this.oneLoginAuthURL = url;
              });
            } else{
              if(error.response && error.response.data && error.response.data.errorObject) {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: error.response.data.errorObject.error_code,
                  error_uuid: error.response.data.errorObject.error_uuid,
                };
              } else {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: "",
                  error_uuid: "",
                };
              }
              this.showMessageDialog = true;
              this.errorMessageShow = true;
            }
          });
      } else {
        this.clearAuthTokenAndCodeVerifier();
      }
    }
  },
  methods: {
    initClientHelper(){
      this.client = google.accounts.oauth2.initCodeClient({
          client_id: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
          scope: 'https://www.googleapis.com/auth/userinfo.email',
          ux_mode: 'popup',
          callback: (response) => {
            let authCode = response.code;
            if (authCode) {
            axios({
              method: "post",
              url: process.env.VUE_APP_BACKEND_URL + "/verifyUser",
              data: { authCode: authCode, authType: "gauth", },
              headers: {
                "content-type": "application/json",
              },
            }).then((response) => {
              if (response.data.message == "Success") {
                if (response.data.userNotificationsData) {
                  this.$store.dispatch("replaceNotifications", response.data.userNotificationsData);
                }
                this.showSpinner = true;
                this.gIdToken = response.data.id_token;
                this.gTdExpiry = response.data.expiry;
                if(response.data.userId){
                  setToLocalStorage("userId", response.data.userId);
                }
                if (response.data.picture) {
                  setToLocalStorage("loginImage", response.data.picture);
                }
              this.completeGoogleAuthentication();
              } else {
                this.clearAuthTokenAndCodeVerifier();
                this.showSpinner = false;
                this.showUnauthorizedDialog = true;
              }
            }).catch((error) => {
              if (error.response && error.response.status && error.response.status == 401) {
                this.clearAuthTokenAndCodeVerifier();
                pkceClient.CreateAuthURL().then((url) => {
                  this.showSpinner = false;
                  this.showUnauthorizedDialog = true;
                  this.oneLoginAuthURL = url;
                });
              } else{
                if(error.response && error.response.data && error.response.data.errorObject) {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: error.response.data.errorObject.error_code,
                    error_uuid: error.response.data.errorObject.error_uuid,
                  };
                } else {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: "",
                    error_uuid: "",
                  };
                }
                this.showMessageDialog = true;
                this.errorMessageShow = true;
              }
            });
          }
        },
      });
    },
    initClient() {
          let that = this;
          let checkGauthLoad = setInterval(function () {
          if(google){
            that.isGauthInit = true;
            that.initClientHelper();
          }
          if(that.isGauthInit) clearInterval(checkGauthLoad);
        }, 200);
    },
    getAuthCode() {
      this.client.requestCode();
    },
    showLoginDialog() {
    this.setloginDialog=true;
    },
    clickCloseLoginDialog() {
      this.setloginDialog=false;
    },
    loginWithEmail(UserDetails) {
      axios({
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL + "/verifyUser",
            data: { 
              "authType": "emailWithPassword",
              "UserDetails":UserDetails 
            },
            headers: {
              "content-type": "application/json",
            },
          }).then ((loginResponse)=> {
            if (loginResponse.data.message === "Success") {
              if (loginResponse.data.userNotificationsData) {
                this.$store.dispatch("replaceNotifications", loginResponse.data.userNotificationsData);
              }
              this.emailIdToken = loginResponse.data.id_token;
              this.emailIdExpiry = loginResponse.data.expiry;
              setToLocalStorage("userId", loginResponse.data.userId);
              setToLocalStorage("authType", "email");
              setToLocalStorage("token", this.emailIdToken);
              setToLocalStorage("id_token_exp", this.emailIdExpiry);
              this.showSpinner = true;
              if (localStorage.getItem("currentPath")) {
                var path = localStorage.getItem("currentPath").split('#');
                var routePath = path[0];
                if (path.length>1)
                {
                  var pathUserId = path[1];
                  if(pathUserId == getFromLocalStorage('userId')) {
                    this.$router.push({
                      path: routePath,
                    });
                  }
                  else {
                    localStorage.removeItem("currentPath");
                    this.$router.push({
                      name: "Home",
                    }); 
                  }
                }
                else {
                  localStorage.removeItem("currentPath");
                  this.$router.push({
                    name: "Home",
                  });
                }
              } else {
                this.$router.push({
                  name: "Home",
                });
              }
              this.showSpinner = false;
            }
          }).catch ((error)=> {
            this.errorMessageShow = true;
            if(error.response && error.response.data && error.response.data.errorObject) {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: error.response.data.errorObject.error_code,
                error_uuid: error.response.data.errorObject.error_uuid,
              };
            }
            if (error.response && error.response.status && error.response.status == 401) {
              this.clearAuthTokenAndCodeVerifier();
            }
            this.showMessageDialog = true;
          })
    },
    completeGoogleAuthentication() {
      setToLocalStorage("authType", "gauth");
      setToLocalStorage("token", this.gIdToken);
      setToLocalStorage("id_token_exp", this.gTdExpiry);
      if(this.reload){
        location.reload(true);
        this.reload = false;
        return;
      }
      this.showSpinner = false;
      if (localStorage.getItem("currentPath")) {
        var path = localStorage.getItem("currentPath").split('#');
        var routePath = path[0];
        if(path.length>1)
        {
          var pathUserId = path[1];
          if(pathUserId == getFromLocalStorage('userId')){
            this.$router.push({
              path: routePath,
            });
          }
          else{
            localStorage.removeItem("currentPath");
            this.$router.push({
              name: "Home",
            });
          }
        }
        else{
          localStorage.removeItem("currentPath");
          this.$router.push({
            name: "Home",
          });
        }
      } else {
        this.$router.push({
          name: "Home",
        });
      }
    },
    clearAuthTokenAndCodeVerifier() {
      if(this.$route.query.code){
        this.$router.replace({ query: null });
      }
      let secondarySupportLanguage = getFromLocalStorage("secondarySupportLanguage");
      if (secondarySupportLanguage) {
        removeFromLocalStorage('secondarySupportLanguage');
      }
      removeFromLocalStorage("token");
      removeFromLocalStorage("authType");
      removeFromLocalStorage("fullName");
      removeFromLocalStorage("userId");
      localStorage.removeItem("auth-url");
      // localStorage.removeItem("code-verifier");
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if(emitObject && emitObject.errorDisplayMessage){
        closeDialogFlag = emitObject.errorDisplayMessage
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", false);
    },
  },
};
</script>

<style scoped>
.centeredSpinnerWrapper {
  height: 100vh;
}
.centeredSpinner {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main_login {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.back_image {
  overflow: hidden;
}

.back_image img {
  height: 100vh;
  background-size: cover;
  background-position: center bottom;
}

.login_box {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.login_box:last-child {
  background-color: white;
}
.logo_small{
  display: none;
}
.hide_logo{
  display: block;
}
.btn_name{
  padding-top:20px;
}

@media screen and (max-width:1250px){

    .main_login {
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      object-fit: cover; 
    }
    .login_box {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    .hide_logo{
      display:none;
      
    }
    .logo{
      display: none;
    }
    .logo_small{
      display:block;
      width:40px;
    }
    .welcome_text{
      font-size:1rem;
    }
    .login_btn{
      width:100%;
      height:40px;
    }
    .btn_name{
      font-size:0.8rem;
    }
}

</style>